@import '~antd/dist/antd.less';

.not-found-wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: @text-color-dark;
	
	p {
		font-size: large;
	}
}

.not-found {
	color: @primary-color;
	margin: 0 0 2vh;
	font-weight: 600;
}

.code {
	color: rgb(204, 214, 216);
	line-height: 1.1;
	margin: 0 0 0.5vh;
	font-weight: 900;
}

@primary-color: #ffd666;