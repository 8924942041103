@import '~antd/dist/antd.less';

.home-wrapper {
	height: 100%;
	align-items: center;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.name-wrapper {
	align-items: center;
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.profession {
	color: @primary-color;
	margin: 0;
	font-weight: 600;
}

.name {
	color: rgb(204, 214, 216);
	line-height: 1.1;
	margin: 0 0 1vh;
	font-weight: 900;
}

.links-wrapper {
	color: white;
}

.icon-wrapper {
	cursor: pointer;
	color: rgb(204, 214, 216);
}

.icon-wrapper:hover {
	color: @primary-color;
}
@primary-color: #ffd666;