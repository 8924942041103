@import '~antd/dist/antd.less';

.header-container {
  height: 10vh;
  padding: 0 4vw;
  background-color: #071528;
}

.main-content {
  background-color: #0a192f;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
}

.scroll-container {
  flex: 1;
  -webkit-flex: 1;
  flex-grow: 1;
  -webkit-flex-grow: 1;
}

.menu-item {
  color: rgb(204, 214, 216);
  height: inherit;
  font-size: 2.5vh;
}

.selected {
  color: @primary-color;
}

.menu-item:hover {
  background-color: @primary-color;
  color: #000;
}

.main-menu {
  float: right;
  height: inherit;
}

@primary-color: #ffd666;